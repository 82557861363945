import React from "react";
import Button from "./Button.js";

class SmsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base: "https://joesazurefunction.azurewebsites.net/api/",
      toNumberValue: "",
      toNumberValueClass: "",
      messageValue: "",
      messageValueClass: "",
      cansubmit: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    let newUrl = "SmsQR";
    console.log(newUrl);

    let body = JSON.stringify({
      ToNumber: this.state.toNumberValue,
      Message: this.state.messageValue,
    });
    this.props.callService(newUrl, body, "SMS QR Code");
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }
  handleKeyUp(event) {
    const name = event.target.name + "Class";
    this.setState({ [name]: "validation" });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} id='SmsForm' className='hidden'>
        <h3>
          Create an SMS Message QR Code
          <a href='/why_use_an_sms_qr'>Learn about SMS QRs</a>
        </h3>

        <div className='column'>
          <label htmlFor='ToNumber' className='required'>
            Send To <i>(using standard phone format)</i>
          </label>
          <input
            id='ToNumber'
            className={this.state.toNumberValueClass}
            name='toNumberValue'
            autoComplete='off'
            placeholder='555 123-4567'
            value={this.state.toNumberValue}
            required={true}
            title='Who do we send this to'
            type='phone'
            maxLength={25}
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />

          <label htmlFor='Message'>
            Message <i>(optional)</i>
          </label>
          <textarea
            id='Message'
            className={this.state.messageValueClass}
            name='messageValue'
            autoComplete='off'
            placeholder='optional'
            value={this.state.messageValue}
            title='Message'
            maxLength={200}
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <Button className='button_stack'>Submit SMS QR</Button>
        </div>
      </form>
    );
  }
}

export default SmsForm;
