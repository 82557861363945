import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
//import Header from './Header';
import App from "./App";
//import FormPicker from "./components/FormPicker";
//import reportWebVitals from './reportWebVitals';

// hinky solution for now.  Will fix later with a proper menu
const toggleMenu = (show) => {
  [...document.getElementById("QRMenu").children].forEach((child) => {
    if (child.id === "Nav" + show) {
      document.getElementById(child.dataset.toggle).className = "";
      child.className = "current";
    } else {
      document.getElementById(child.dataset.toggle).className = "hidden";
      child.className = "";
    }
  });
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <h3>Choose QR Type:</h3>
    
    <nav className="sidebar" id="QRMenu">
      <button
        id="NavUrl"
        title="Make a Url QR"
        className="current"
        data-toggle="UrlForm"
        onClick={() => {
          toggleMenu("Url");
        }}
      >
        Url
      </button>
      <button
        id="NavWifi"
        title="Make a Wifi QR"
        data-toggle="WifiForm"
        onClick={() => {
          toggleMenu("Wifi");
        }}
      >
        WiFi
      </button>
      <button
        id="NavEmail"
        title="Make an Email QR"
        data-toggle="EmailForm"
        onClick={() => {
          toggleMenu("Email");
        }}
      >
        Email 
      </button>
      <button
        id="NavPlainText"
        title="Make a Plain Text QR"
        data-toggle="PlainTextForm"
        onClick={() => {
          toggleMenu("PlainText");
        }}
      >
        Plain Text 
      </button>
      <button
        id="NavSms"
        title="Make an SMS QR"
        data-toggle="SmsForm"
        onClick={() => {
          toggleMenu("Sms");
        }}
      >
        SMS
      </button>
      <button
        id="NavContact"
        title="Make a Contact QR"
        data-toggle="ContactForm"
        onClick={() => {
          toggleMenu("Contact");
        }}
      >
        Contact
      </button>
    </nav>
      <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
