import React from "react";
import Button from "./Button.js";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base: "https://joesazurefunction.azurewebsites.net/api/ContactQR",
      text: "",
      firstNameValue: "",
      firstNameValueClass: "",
      lastNameValue: "",
      lastNameValueClass: "",
      organizationValue: "",
      organizationValueClass: "",
      titleValue: "",
      titleValueClass: "",
      phoneValue: "",
      phoneValueClass: "",
      mobilePhoneValue: "",
      mobilePhoneValueClass: "",
      workPhoneValue: "",
      workPhoneValueClass: "",
      emailValue: "",
      emailValueClass: "",
      websiteValue: "",
      websiteValueClass: "",
      addressOrderValue: "1",
      addressOrderValueClass: "",
      //   houseNumberValue: "",
      //   houseNumberValueClass: "",
      streetValue: "",
      streetValueClass: "",
      cityValue: "",
      cityValueClass: "",
      stateRegionValue: "",
      stateRegionValueClass: "",
      zipCodeValue: "",
      zipCodeValueClass: "",
      countryValue: "",
      countryValueClass: "",
      cansubmit: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    let newUrl = "ContactQR";

    let body = JSON.stringify({
      FirstName: this.state.firstNameValue,
      LastName: this.state.lastNameValue,
      Organization: this.state.organizationValue,
      Title: this.state.titleValue,
      Phone: this.state.phoneValue,
      MobilePhone: this.state.mobilePhoneValue,
      WorkPhone: this.state.workPhoneValue,
      Email: this.state.emailValue,
      Website: this.state.websiteValue,
      AddressOrderType: this.state.addressOrderValue,
      //HouseNumberValue:this.state.houseNumberValue,
      Street: this.state.streetValue,
      City: this.state.cityValue,
      StateRegion: this.state.stateRegionValue,
      ZipCode: this.state.zipCodeValue,
      Country: this.state.countryValue,
    });
    
    this.props.callService(newUrl, body, "Contact QR Code");
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }
  handleKeyUp(event) {
    const name = event.target.name + "Class";
    this.setState({ [name]: "validation" });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} id='ContactForm' className='hidden'>
        <h3>
          Create a Contact QR Code{" "}
          <a href='/why_use_a_contact_qr'>Learn about Contact QRs</a>
        </h3>

        <div className='column'>
          <label htmlFor='FirstName' className='required'>
            First Name
          </label>
          <input
            id='FirstName'
            className={this.state.firstNameValueClass}
            name='firstNameValue'
            autoComplete='off'
            placeholder=''
            value={this.state.firstNameValue}
            required={true}
            title='First Name'
            type='text'
            maxLength={50}
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <label htmlFor='LastName' className='required'>
            Last Name
          </label>
          <input
            id='LastName'
            className={this.state.lastNameValueClass}
            name='lastNameValue'
            autoComplete='off'
            placeholder=''
            value={this.state.lastNameValue}
            required={true}
            title='Last Name'
            type='text'
            maxLength={50}
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <label htmlFor='Email' className='required'>
            Email
          </label>
          <input
            id='Email'
            className={this.state.emailValueClass}
            name='emailValue'
            autoComplete='off'
            placeholder='me@myemail.com'
            value={this.state.emailValue}
            title='Who do we send this to'
            type='email'
            required={true}
            maxLength={200}
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <label htmlFor='Organization'>Organization</label>
          <input
            id='Organization'
            className={this.state.organizationValueClass}
            name='organizationValue'
            autoComplete='off'
            placeholder=''
            value={this.state.organizationValue}
            title='Organization'
            type='text'
            maxLength={50}
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <label htmlFor='Title'>Title</label>
          <input
            id='Title'
            className={this.state.titleValueClass}
            name='titleValue'
            autoComplete='off'
            placeholder=''
            value={this.state.titleValue}
            title='Title'
            type='text'
            maxLength={50}
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <label htmlFor='Phone'>Phone</label>
          <input
            id='Phone'
            className={this.state.phoneValueClass}
            name='phoneValue'
            autoComplete='off'
            placeholder='555 123-4567'
            value={this.state.phoneValue}
            title='Phone Number'
            type='phone'
            maxLength={25}
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <label htmlFor='MobilePhone'>Mobile Phone</label>
          <input
            id='MobilePhone'
            className={this.state.mobilePhoneValueClass}
            name='mobilePhoneValue'
            autoComplete='off'
            placeholder='555 123-4567'
            value={this.state.mobilePhoneValue}
            title='Mobile Phone Number'
            type='phone'
            maxLength={25}
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <label htmlFor='WorkPhone'>Work Phone</label>
          <input
            id='WorkPhone'
            className={this.state.workPhoneValueClass}
            name='workPhoneValue'
            autoComplete='off'
            placeholder='555 123-4567'
            value={this.state.workPhoneValue}
            title='Work Phone Number'
            type='phone'
            maxLength={25}
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />

          <label htmlFor='WebsiteValue'>
            Website <i>(http:// or https://)</i>
          </label>
          <input
            id='WebsiteValue'
            className={this.state.websiteValueClass}
            name='websiteValue'
            autoComplete='off'
            placeholder='https://'
            maxLength={1000}
            value={this.state.websiteValue}
            title='https:// or http:// urls only'
            pattern='^[hH][tT][tT][pP][sS]?://[\w\W]+'
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <h3
            id='AddressFormat'
            title='Sets the address field order when displaying the address'
          >
            Address Format
          </h3>
          <div className='row row_radio'>
            <label htmlFor='AddressOrderValue_0'>
              <input
                id='AddressOrderValue_0'
                className={this.state.addressOrderValueClass}
                name='addressOrderValue'
                type='radio'
                value='1'
                checked={this.state.addressOrderValue === "1"}
                title='North America and others'
                aria-describedby='AddressFormat'
                onKeyUp={this.handleKeyUp}
                onChange={this.handleInputChange}
              />
              North America and others
            </label>
            <label htmlFor='AddressOrderValue_1'>
              <input
                id='AddressOrderValue_1'
                className={this.state.addressOrderValueClass}
                name='addressOrderValue'
                type='radio'
                value='0'
                checked={this.state.addressOrderValue === "0"}
                title='European countries and others'
                aria-describedby='AddressFormat'
                onKeyUp={this.handleKeyUp}
                onChange={this.handleInputChange}
              />
              European countries and others
            </label>
          </div>

          <label htmlFor='StreetValue'>Street</label>
          <input
            id='StreetValue'
            className={this.state.streetValueClass}
            name='streetValue'
            autoComplete='off'
            maxLength={100}
            value={this.state.streetValue}
            title='The Street Name'
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <label htmlFor='CityValue'>City</label>
          <input
            id='CityValue'
            className={this.state.cityValueClass}
            name='cityValue'
            autoComplete='off'
            maxLength={100}
            value={this.state.cityValue}
            title='City'
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <label htmlFor='StateRegionValue'>State/Region</label>
          <input
            id='StateRegionValue'
            className={this.state.stateRegionValueClass}
            name='stateRegionValue'
            autoComplete='off'
            maxLength={50}
            value={this.state.stateRegionValue}
            title='State or Region'
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <label htmlFor='ZipCodeValue'>Zip/Postal Code</label>
          <input
            id='ZipCodeValue'
            className={this.state.zipCodeValueClass}
            name='zipCodeValue'
            autoComplete='off'
            maxLength={20}
            value={this.state.zipCodeValue}
            title='Zip or Postal code'
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <label htmlFor='CountryValue'>Country</label>
          <input
            id='CountryValue'
            className={this.state.countryValueClass}
            name='countryValue'
            autoComplete='off'
            maxLength={50}
            value={this.state.countryValue}
            title='Country'
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <Button className='button_stack'>Submit Contact QR</Button>
        </div>
      </form>
    );
  }
}

export default ContactForm;
