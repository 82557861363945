import React from "react";
import Button from "./Button.js";

class PlainTextForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base: "https://joesazurefunction.azurewebsites.net/api/PlainTextQR",
      text: "",
      textValue: "",
      textValueClass: "",
      cansubmit: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    let newUrl = "PlainTextQR";

    let body = JSON.stringify({
      text: this.state.textValue,
    });
    this.props.callService(newUrl, body, "Plain Text QR Code");
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }
  handleKeyUp(event) {
    const name = event.target.name + "Class";
    this.setState({ [name]: "validation" });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} id='PlainTextForm' className='hidden'>
        <h3>
          Create a Plain Text QR Code
          <a href='/why_use_a_plain_text_qr'>Learn about Text QRs</a>
        </h3>

        <div className='column'>
          <label htmlFor='TextValue' className='required'>
            Message
          </label>
          <textarea
            id='TextValue'
            className={this.state.textValueClass}
            name='textValue'
            autoComplete='off'
            placeholder='Your Message'
            value={this.state.utextValue}
            required={true}
            maxLength={500}
            title='Any message people can scan'
            onKeyUp={this.handleKeyUp}
            onChange={this.handleInputChange}
          />
          <Button className='button_stack'>Submit Plain Text QR</Button>
        </div>
      </form>
    );
  }
}

export default PlainTextForm;
